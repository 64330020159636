// @codekit-prepend "details-element-polyfill.js";
// @codekit-prepend "jquery.unveil.js";
// @codekit-prepend "iscroll.js";
// @codekit-prepend "slick.min.js";
// @codekit-prepend "lity.min.js";
// @codekit-prepend "faq-animation.js";

(function ($) {
	'use strict';

	$(function () {
		// AJAX VARIABLES
		var loader = $('#load-more-posts');
		var content = $('#ajax_posts');
		var ppp = 4;
		var cat = loader.data('category');
		var tag = loader.data('tag');
		var type = loader.data('contenttype');
		var query = loader.data('query');
		var offset = content.find('.posts').length;
		// var offset = 4;

		// OTHERS
		var body = $('body');
		var mainContainer = $('main');
		var scrollIndicator = $('#scroll-indicator');
		var benefitSelector = $('.home-benefit-selector');
		var benefitContainer = $('.home-benefit');
		var filterOpener = $('#filter-opener');

		var nav = $('nav');

		var mobileBreakpoint = 767;

		// FOR MAILCHIMP FOOTER
		var signupForm = $('#signup-form');
		var signupButton = $('#signup-submit');
		var mailchimp_field_identifier = 'MERGE0';
		var href = signupButton.attr('href');

		// HP BENEFITS
		var prevClicked, thisClicked;

		var benefitParent = $('#benefits-container');
		var benefitHeight = benefitParent.height();

		// BLOCKS
		var fileBlock = $('.cta-download');

		// SCROLLING VARS
		var $window = $(window);
		var scrollAmount,
			window_height,
			window_top_position,
			window_bottom_position;
		var sharing = $('#sharing');

		// PATHS
		var path;

		var cookiePromptTest = false; //change this to true to test the message
		var cookieMessage =
			'<div id="cookie-prompt" class="alert alert-danger"><p>We use cookies to improve this website but don’t collect any personal data from you without your consent. By using this website you accept our <a href="https://www.matter-of-focus.com/website-privacy-policy/" target="_blank" rel="nofollow" title="Cookies Policy">privacy policy</p></a>.<a href="#" class="btn btn-outline" id="cookieCloser">OK</a></div>';

		// BLOG
		var searchPanel = $('.blog-filter-panel');
		var listTrigger = $('#list-trigger');
		var windowWidth = $(window).innerWidth() + 17;
		var postContainer = $('.post-container');
		var searchPanelHeight = searchPanel.outerHeight();
		var postHero = $('.hero');
		var postHeroInitialPadding = postHero.css('padding-bottom');

		var ajaxPosts = $('#ajax_posts');
		var ajaxSide = $('#ajax_side');
		var ajaxSidePosts = $('#ajax_side_posts');
		var ajaxSidePostsIsOriginal = true;
		var ajaxActive = $('#ajax_active');

		var catFilter, tagFilter, typeFilter;

		if (mainContainer.hasClass('template-single')) {
			var articleBody = $('#article');
			var scrollHeight = articleBody.offset().top + articleBody.height() - 140;

			// Add lightbox to images
			articleBody.find('img').on('click', lity);
		}

		// BLOG filter popup
		listTrigger.on('click', function () {
			searchPanel.toggleClass('-showList');

			// mobile no more scroll
			if (searchPanel.hasClass('-sticky') && windowWidth < mobileBreakpoint) {
				console.log(windowWidth + ' < ' + mobileBreakpoint);
				body.toggleClass('nav-open');
			}
		});

		// MOBILE BLOG FILTERS
		filterOpener.on('click', function () {
			$('#side-panel').toggleClass('filter-open');
			// body.toggleClass('nav-open');
			e.preventDefault();
		});

		// LOADING MORE POSTS VIA AJAX

		loader.on('click', load_ajax_posts);

		function load_ajax_posts() {
			if (
				!(
					loader.hasClass('post_loading_loader') ||
					loader.hasClass('post_no_more_posts')
				)
			) {
				var postData = {
					ppp: ppp,
					offset: offset,
					cat: cat,
					tag: tag,
					query: query,
					action: 'load_more_post_ajax',
				};

				$.ajax({
					type: 'POST',
					dataType: 'html',
					url: load_more_post_ajax.ajaxurl,
					data: postData,
					beforeSend: function (data) {
						loader.addClass('post_loading_loader').html('Loading...');
						// console.log('data' + data.length);
					},
					success: function (data) {
						// var data = $(data);
						if (data.length) {
							// var newElements = data.css({ opacity: 0 });
							content.append(data);
							// newElements.animate({ opacity: 1 });
							loader.removeClass('post_loading_loader').html('Load more');
							// console.log('asd');
						} else {
							loader
								.removeClass('post_loading_loader')
								.addClass('post_no_more_posts hdden')
								.html('That’s all.');
						}
						// var obj = jQuery.parseJSON(data);
						// console.log('success ' + data);
						// console.log('success ' + obj);
					},
					error: function (jqXHR, textStatus, errorThrown) {
						loader.html(
							$.parseJSON(jqXHR.responseText) +
								' :: ' +
								textStatus +
								' :: ' +
								errorThrown
						);
						// console.log(jqXHR);
					},
					complete: function (data) {
						if (data.length < ppp) {
							loader
								.removeClass('post_loading_loader')
								.addClass('post_no_more_posts hdden')
								.html('That’s all.');
						}
					},
				});
			}
			offset += ppp;
			return false;
		}

		var clickedItem, popstateFilter, urlString;
		var fromBreadcrumb = false;
		var originalUrl = window.location.href.split('?')[0];
		var ajaxLoader = $('#ajaxLoader');

		$('.list-panels')
			.find('a')
			.on('click', function (event) {
				fromBreadcrumb = false;
				event.preventDefault();
				var $el = $(this);
				clickedItem = $el;
				if ($el.data('cat')) {
					catFilter = $el.data('cat');
				}
				if ($el.data('tag')) {
					tagFilter = $el.data('tag');
				}
				if ($el.data('type')) {
					typeFilter = $el.data('type');
				}
				console.log(catFilter + ' / ' + tagFilter + ' / ' + typeFilter);

				if (windowWidth > mobileBreakpoint) {
					filter_ajax_posts();
				} else {
					clickedItem.parent('li').siblings('li').removeClass('-active');
					clickedItem.parent('li').addClass('-active');

					console.log('loaded');
					// dynamic breadcrumbs in three tiers
					var newValue = clickedItem.data('text');
					var newColour = clickedItem.data('colour');

					if (clickedItem.data('cat')) {
						// console.log('cat2');
						$('#breadcrumb-cat').removeClass(function (index, className) {
							return (className.match(/(^|\s)blog-category-\S+/g) || []).join(
								' '
							);
						});
						$('#breadcrumb-cat')
							.addClass('blog-category-' + newColour)
							.html(newValue)
							.removeClass('hidden');
					} else if (clickedItem.data('type')) {
						// console.log('type2');
						$('#breadcrumb-type').html(newValue).removeClass('hidden');
					} else if (clickedItem.data('tag')) {
						// console.log('tag');
						$('#breadcrumb-tag').html(newValue).removeClass('hidden');
					}
				}
			});

		$('#applyFilters').on('click', function (event) {
			event.preventDefault();
			console.log(
				'ACTIVE 2: ' + catFilter + ' / ' + tagFilter + ' / ' + typeFilter
			);

			filter_ajax_posts();

			searchPanel.removeClass('-sticky -hiding -animateIn -showList');
			body.removeClass('nav-open');
			postHero.css('padding-bottom', postHeroInitialPadding);
		});

		// pills to deactivate filter
		$('#active-filter-list > li').on('click', function (event) {
			var $el = $(this);
			clickedItem = $el;
			fromBreadcrumb = true;

			// console.log($el.is('#breadcrumb-cat'));

			if ($el.is('#breadcrumb-cat')) {
				// console.log('ccc');
				catFilter = '';

				filter_ajax_posts();
				$el.addClass('hidden');
				$('.-categories').find('li').removeClass('-active');
			} else if ($el.is('#breadcrumb-type')) {
				// console.log('typetype');
				typeFilter = '';
				filter_ajax_posts();
				$el.addClass('hidden');
				$('.-types').find('li').removeClass('-active');
			} else if ($el.is('#breadcrumb-tag')) {
				// console.log('tagtag');
				tagFilter = '';
				filter_ajax_posts();
				$el.addClass('hidden');
				$('.-tags').find('li').removeClass('-active');
			}

			// console.log('ACTIVE: ' + catFilter + ' / ' + tagFilter + ' / ' + typeFilter);

			if (
				$('#breadcrumb-cat').hasClass('hidden') &&
				$('#breadcrumb-type').hasClass('hidden') &&
				$('#breadcrumb-tag').hasClass('hidden')
			) {
				ajaxActive.fadeOut();
				urlString = '';
				listTrigger.removeClass('-active');
			}
		});

		$('#ajax_clear').on('click', function (event) {
			event.preventDefault();
			fromBreadcrumb = true;
			ajaxSidePostsIsOriginal = false;
			catFilter = '';
			typeFilter = '';
			tagFilter = '';
			clickedItem = $(this);

			filter_ajax_posts();

			$('.list-panels').find('li').removeClass('-active');
			$('#active-filter-list').find('li').addClass('hidden');
			ajaxActive.fadeOut();

			listTrigger.removeClass('-active');
		});

		// new ajax filtering
		function filter_ajax_posts() {
			var postData = {
				// 'ppp': ppp,
				// 'offset': offset,
				// 'cat' : cat,
				// 'tag' : tag,
				// 'query': query,
				cat: catFilter,
				tag: tagFilter,
				type: typeFilter,
				action: 'filter_posts_ajax',
			};

			// GET MAIN POSTS
			$.ajax({
				type: 'POST',
				dataType: 'html',
				// contentType: 'application/json',
				url: load_more_post_ajax.ajaxurl,
				data: postData,
				beforeSend: function (data) {
					// loader.addClass('post_loading_loader').html('Loading...');
					// console.log('data' + data.length);
					if (!ajaxLoader.hasClass('hidden')) {
						ajaxLoader.addClass('hidden');
					}
					ajaxPosts.html('Loading...');

					if (clickedItem.data('side-posts') || !ajaxSidePostsIsOriginal) {
						ajaxSidePosts.html('Loading...');
					}

					if (!fromBreadcrumb) {
						if (windowWidth > mobileBreakpoint) {
							clickedItem.parent('li').siblings('li').removeClass('-active');
							clickedItem.parent('li').addClass('-active');

							console.log('loaded');
							// dynamic breadcrumbs in three tiers
							var newValue = clickedItem.data('text');
							var newColour = clickedItem.data('colour');

							if (clickedItem.data('cat')) {
								// console.log('cat2');
								$('#breadcrumb-cat').removeClass(function (index, className) {
									return (
										className.match(/(^|\s)blog-category-\S+/g) || []
									).join(' ');
								});
								$('#breadcrumb-cat')
									.addClass('blog-category-' + newColour)
									.html(newValue)
									.removeClass('hidden');
							} else if (clickedItem.data('type')) {
								// console.log('type2');
								$('#breadcrumb-type').html(newValue).removeClass('hidden');
							} else if (clickedItem.data('tag')) {
								// console.log('tag');
								$('#breadcrumb-tag').html(newValue).removeClass('hidden');
							}
						}

						ajaxActive.fadeIn();
					}

					$('html, body').animate(
						{
							scrollTop: ajaxPosts.offset().top - 150,
						},
						400
					);
				},
				success: function (data) {
					// var data = $(data);

					if (data.length) {
						// var newElements = data.css({ opacity: 0 });

						ajaxPosts.html('');
						ajaxPosts.append(data);
						// newElements.animate({ opacity: 1 });
						// loader.removeClass('post_loading_loader').html('Load more');
						urlString = '?';
						var urlStringArray = [];
						if (!popstateFilter) {
							if (tagFilter) {
								// urlString += '?tag=' + tagFilter;
								urlStringArray.push('tag=' + tagFilter);
							}
							if (typeFilter) {
								// urlString += '?type=' + typeFilter;
								urlStringArray.push('type=' + typeFilter);
							}

							if (catFilter) {
								// urlString += '?category=' + catFilter;
								urlStringArray.push('category=' + catFilter);
							}
							console.log(
								'ACTIVE 3: ' +
									catFilter +
									' / ' +
									tagFilter +
									' / ' +
									typeFilter
							);

							if (typeFilter || tagFilter || catFilter) {
								listTrigger.addClass('-active');
							}

							for (var i = urlStringArray.length - 1; i >= 0; i--) {
								urlString += urlStringArray[i].toString();
								if (i > 0) {
									urlString += '&';
								}
							}

							window.history.pushState('', '', urlString);

							getSidePosts();
						}
					} else {
						// loader.removeClass('post_loading_loader').addClass('post_no_more_posts hdden').html('That’s all.');
						// ajaxPosts.html('Nothing');
						ajaxPosts.html('');
						ajaxLoader.removeClass('hidden');
						listTrigger.removeClass('-active');
						console.log('data');
						// clickedItem.parent('li').removeClass('-active');
					}
					// var obj = jQuery.parseJSON(data);
					// console.log('success ' + data);
					// console.log('success ' + obj);
				},
				error: function (jqXHR, textStatus, errorThrown) {
					loader.html(
						$.parseJSON(jqXHR.responseText) +
							' :: ' +
							textStatus +
							' :: ' +
							errorThrown
					);
					// console.log(jqXHR);
				},
				complete: function (data) {
					clickedItem = null;

					if (!urlString) {
						window.history.pushState('', '', originalUrl);
						listTrigger.removeClass('-active');
					}
				},
			});

			return false;
		}

		benefitSelector.on('click', function () {
			benefitContainer.removeClass('active');
			thisClicked = $(this).parent('.home-benefit');

			thisClicked.addClass('active');

			prevClicked = thisClicked;
		});

		signupButton.on('click', function () {
			var emailAddress = signupForm.val();
			signupButton.attr(
				'href',
				href + '&' + mailchimp_field_identifier + '=' + emailAddress
			);
		});

		fileBlock.find('a').on('click', function () {
			var thankyouContainer = $(this)
				.parents('.cta-download')
				.children('#thankyou-container');
			fileBlock.find('#download-container').fadeOut();
			setTimeout(function () {
				thankyouContainer.fadeIn();
			}, 500);
		});

		// MOBILE NAV
		$('.custom-toggle').on('click', function (e) {
			toggleMenu();
			e.preventDefault();
		});

		$(document).ready(function () {
			$('#carousel').slick({
				slidesToShow: 2.2,
				// centerMode: true,
				// centerPadding: '10%',
				slidesToScroll: 1,
				infinite: false,
				prevArrow: $('#prevButton'),
				nextArrow: $('#nextButton'),
				responsive: [
					{
						breakpoint: 1100,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 1,
						},
					},
					{
						breakpoint: 769,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1,
						},
					},
				],
			});

			$('#carousel').on(
				'beforeChange',
				function (event, slick, currentSlide, nextSlide) {
					$('.slick-slide li').removeClass('active-slide');
				}
			);

			$('#carousel').on(
				'afterChange',
				function (event, slick, currentSlide, nextSlide) {
					$('.slick-current li').addClass('active-slide');
				}
			);

			window_height = $window.height();
			window_top_position = $window.scrollTop();
			window_bottom_position = window_top_position + window_height;

			$window.trigger('scroll');

			// BLOG ACTIVE filters
			if ($('main').hasClass('template-blog')) {
				var activeCat = $.urlParam('category');
				var activeType = $.urlParam('type');
				var activeTag = $.urlParam('tag');

				console.log('asdasd');

				if (activeCat) {
					// console.log(activeCat);
					var activeCatItem = $('.-categories').find(
						'a[data-cat="' + activeCat + '"]'
					);
					activeCatItem.parent().addClass('-active');
					$('#breadcrumb-cat')
						.addClass('blog-category-' + activeCatItem.data('colour'))
						.html(activeCatItem.data('text'))
						.removeClass('hidden');
				}
				if (activeType) {
					var activeTypeItem = $('.-types').find(
						'a[data-type="' + activeType + '"]'
					);
					activeTypeItem.parent().addClass('-active');
					$('#breadcrumb-type')
						.html(activeTypeItem.data('text'))
						.removeClass('hidden');
				}
				if (activeTag) {
					var activeTagItem = $('.-tags').find(
						'a[data-tag="' + activeTag + '"]'
					);
					activeTagItem.parent().addClass('-active');
					$('#breadcrumb-tag')
						.html(activeTagItem.data('text'))
						.removeClass('hidden');
				}

				if (activeCat || activeType || activeTag) {
					ajaxActive.fadeIn();
					listTrigger.addClass('-active');
				}

				getSidePosts();
			}

			$(function () {
				if (
					cookiePromptTest ||
					checkCookie('matter_of_focus_policy_cookie') != 'on'
				) {
					//header is the id of the element the message will appear before
					$('#cookie-container').prepend(cookieMessage);
				}
			});
			$('#cookie-container').on('click', '#cookieCloser', function (event) {
				event.preventDefault();
				closeCookiePrompt();
			});
		});

		// NEEDS RESIZE HANDLER

		$(window).resize(function (event) {
			windowWidth = $(window).innerWidth() + 17;
		});

		$(window).scroll(function (event) {
			scrollAmount = $(this).scrollTop();
			window_top_position = scrollAmount;
			window_bottom_position = window_top_position + window_height;

			check_if_in_view();
		});

		function check_if_in_view() {
			$('.reveal').each(function () {
				var $element = $(this);
				var element_height = $element.outerHeight();
				var element_top_position = $element.offset().top;

				// console.log( $element );
				var element_bottom_position = element_top_position + element_height;

				//check to see if this current container is within viewport
				if (
					element_bottom_position >= window_top_position &&
					element_top_position <= window_bottom_position
				) {
					$element.removeClass('reveal-top');
				} else {
					// $element.addClass('reveal-top');
				}
			});

			if (mainContainer.hasClass('template-single')) {
				// console.log( sharing.offset().top );
				// console.log(scrollAmount);

				if (
					scrollAmount >= sharing.offset().top &&
					scrollAmount <= scrollHeight
				) {
					sharing.addClass('sticky').removeClass('opacity-0');
				} else if (scrollAmount < sharing.offset().top) {
					sharing.removeClass('sticky');
				}
				if (scrollAmount > scrollHeight) {
					sharing.addClass('opacity-0');
				}
			}

			// BLOG
			// blog sticky
			if (
				mainContainer.hasClass('template-blog') &&
				!mainContainer.hasClass('-filtered')
			) {
				// MAKE NAV STICKY
				// DESKTOP
				if (windowWidth >= mobileBreakpoint) {
					// IN
					if (scrollAmount > postContainer.offset().top) {
						searchPanel.addClass('-sticky -hiding');
						setTimeout(function () {
							searchPanel.addClass('-animateIn');
						}, 200);
						postHero.css('padding-bottom', searchPanelHeight);

						// OUT
					} else if (scrollAmount < postContainer.offset().top) {
						if (!mainContainer.hasClass('search')) {
							searchPanel.removeClass('-sticky -hiding -animateIn -showList');
							postHero.css('padding-bottom', postHeroInitialPadding);
						} else {
							searchPanel.removeClass('-sticky -animateIn -showList');
						}
					}

					// PHONE
				} else {
					// IN
					if (scrollAmount > postContainer.offset().top - 143) {
						searchPanel.addClass('-sticky -hiding');
						setTimeout(function () {
							searchPanel.addClass('-animateIn');
						}, 200);
						postHero.css('padding-bottom', searchPanelHeight);

						// OUT
					} else if (
						scrollAmount < postContainer.offset().top + 143 &&
						!searchPanel.hasClass('.-showList')
					) {
						searchPanel.removeClass('-sticky -hiding -animateIn -showList');
						postHero.css('padding-bottom', postHeroInitialPadding);
					}
				}
			}

			// filtered sticky
			if (
				mainContainer.hasClass('template-blog') &&
				mainContainer.hasClass('-filtered')
			) {
				if (scrollAmount > searchPanel.offset().top) {
					searchPanel.addClass('-sticky');
				} else if (scrollAmount < $('.navigation').height()) {
					searchPanel.removeClass('-sticky');
				}
			}
		}

		function getSidePosts() {
			console.log('ccc');
			console.log(clickedItem);

			// GET SIDE POSTS
			if (clickedItem.data('side-posts')) {
				var sideIds = clickedItem.data('side-posts');
				var type = typeof sideIds;
				console.log('ajax2 ' + sideIds + ' ; ' + type);
				var sideHeader = clickedItem.data('side-title');

				ajaxSidePostsIsOriginal = false;

				var postData2 = {
					ids: sideIds,
					action: 'get_side_posts_ajax',
				};
				$.ajax({
					type: 'POST',
					dataType: 'html',
					// contentType: 'application/json',
					url: load_more_post_ajax.ajaxurl,
					data: postData2,
					// beforeSend: function(data2){
					// 	ajaxSidePosts.html('Loading...');
					// },
					success: function (data2) {
						// console.log(data2);
						ajaxSide.find('h5').html(sideHeader);
						// ajaxSidePosts.html();
						ajaxSidePosts.html(data2);
					},
				});
			} else if (!ajaxSidePostsIsOriginal) {
				var originalSideIds = ajaxSide.data('side-posts');
				var originalSideHeader = ajaxSide.data('title');
				var postData2 = {
					ids: originalSideIds,
					action: 'get_side_posts_ajax',
				};
				$.ajax({
					type: 'POST',
					dataType: 'html',
					// contentType: 'application/json',
					url: load_more_post_ajax.ajaxurl,
					data: postData2,

					success: function (data2) {
						// console.log(data2);
						ajaxSide.find('h5').html(originalSideHeader);
						ajaxSidePosts.html();
						ajaxSidePosts.html(data2);

						ajaxSidePostsIsOriginal = true;
					},
				});
			}
		}

		// RESPONSIVE NAV
		function toggleMenu() {
			// set timeout so that the panel has a chance to roll up
			// before the menu switches states
			if (nav.hasClass('open')) {
				setTimeout(function () {
					body.toggleClass('nav-open');
					nav.toggleClass('open');
				}, 150);
			} else {
				body.toggleClass('nav-open');
				nav.toggleClass('open');
			}

			$('.custom-toggle').toggleClass('x');
		}

		function closeMenu() {
			if (nav.hasClass('open')) {
				toggleMenu();
			}
		}

		function closeCookiePrompt() {
			if (!cookiePromptTest) {
				createCookie('matter_of_focus_policy_cookie', 'on', 30); //don't show message for 30 days once closed (change if required)
			}
			$('#cookie-prompt').remove();
		}

		function createCookie(name, value, days) {
			if (days) {
				var date = new Date();
				date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
				var expires = '; expires=' + date.toGMTString();
			} else {
				var expires = '';
			}
			document.cookie = name + '=' + value + expires + '; path=/';
			// console.log('created');
		}

		function checkCookie(name) {
			var nameEQ = name + '=';
			var ca = document.cookie.split(';');
			for (var i = 0; i < ca.length; i++) {
				var c = ca[i];
				while (c.charAt(0) == ' ') c = c.substring(1, c.length);
				if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
			}
			console.log('checked!');
			return null;
		}

		function eraseCookie(name) {
			createCookie(name, '', -1);
		}

		$.urlParam = function (name) {
			var results = new RegExp('[?&]' + name + '=([^&#]*)').exec(
				window.location.href
			);
			if (results == null) {
				return null;
			}
			return decodeURI(results[1]) || 0;
		};
	});

	// FAQ toggling
	$(function () {
		miscPolyfillsForIE();
		let makeMePretty = document.querySelector('#faqAnimation');
		let faq = new Collapse(makeMePretty).init();
	});
})(jQuery);
